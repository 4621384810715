exports.components = {
  "component---src-components-templates-audio-work-details-js": () => import("./../../../src/components/Templates/AudioWorkDetails.js" /* webpackChunkName: "component---src-components-templates-audio-work-details-js" */),
  "component---src-components-templates-video-work-details-js": () => import("./../../../src/components/Templates/VideoWorkDetails.js" /* webpackChunkName: "component---src-components-templates-video-work-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-library-music-js": () => import("./../../../src/pages/works/library-music.js" /* webpackChunkName: "component---src-pages-works-library-music-js" */),
  "component---src-pages-works-sound-design-js": () => import("./../../../src/pages/works/sound-design.js" /* webpackChunkName: "component---src-pages-works-sound-design-js" */),
  "component---src-pages-works-video-js": () => import("./../../../src/pages/works/video.js" /* webpackChunkName: "component---src-pages-works-video-js" */)
}

